/**
 * NOTE FROM March, 17h 2024
 * Monetization has been disabled for the beta to run smoothly
 * and to boost acquisition
 */
import { Navigate } from "@solidjs/router";
// import { Show, createEffect, onMount } from "solid-js";
// import RefreshCCW from "#lucide-solid/icons/refresh-ccw";
// import CheckCheck from "#lucide-solid/icons/check-check";
//
// import { css } from "#style/css";
// import { vstack } from "#style/patterns";
// import { Center, styled, VStack, Box } from "#style/jsx";
//
// import { AppPageContainer } from "#root/components/AppPageContainer";
// import { AppDashboardHeader } from "#root/components/AppDashboardHeader";
// import { Button } from "#root/components/Button";
// import { Typography } from "#root/components/Typography";
// import { useUserContext, useUserUpdates } from "#root/domain/user";
// import { generateProductOverlayUrl } from "#root/domain/payments";
// import { useTranslation } from "#root/domain/i18n";

// export default function CreditsPage() {
//   const { t } = useTranslation();
//   const userCtx = useUserContext();
//   const { isConnected, addEventListener } = useUserUpdates();
//
//   const listItemStyle = css({
//     display: "inline-flex",
//     flexDirection: "row",
//     justifyContent: "flex-start",
//     alignItems: "center",
//     gap: "4",
//   });
//
//   const onReloadUserData = () => userCtx.user.refetch();
//
//   createEffect(() => {
//     if (isConnected()) {
//       addEventListener("credits:updated", () => {
//         onReloadUserData();
//       });
//     }
//   });
//
//   onMount(() => {
//     LemonSqueezy.Refresh();
//   });
//
//   return (
//     <Show when={userCtx.user.data} fallback={null}>
//       {(user) => (
//         <AppPageContainer>
//           <AppDashboardHeader pageTitle={t("credits.pageTitle")}>
//             <Button
//               size="lg"
//               leftIcon={() => <RefreshCCW />}
//               tag="button"
//               color="yellow"
//               ctx="dark"
//               onClick={onReloadUserData}
//             >
//               {t("credits.refreshCta")}
//             </Button>
//           </AppDashboardHeader>
//
//           <VStack
//             w="full"
//             py="10"
//             gap="10"
//             justifyContent="flex-start"
//             alignItems="flex-start"
//           >
//             <Typography
//               textStyle="heading1"
//               tag="h2"
//               class={vstack({
//                 alignItems: "flex-start",
//                 gap: "0",
//               })}
//             >
//               <styled.span color="white">
//                 {t("credits.creditsCount.0", { credits: user().credits })}
//               </styled.span>
//               <styled.span color="lightGray">
//                 {t("credits.creditsCount.1")}
//               </styled.span>
//             </Typography>
//           </VStack>
//
//           <VStack
//             backgroundColor="darkGray"
//             p="10"
//             mx="-10"
//             justifyContent="flex-start"
//             alignItems="flex-start"
//           >
//             <Typography
//               textStyle="smallImportant"
//               class={css({ color: "lightGray", mb: "10" })}
//             >
//               {t("credits.whatYouGetTitle")}
//             </Typography>
//
//             <styled.ul
//               display="flex"
//               flexDirection="column"
//               justifyContent="flex-start"
//               alignItems="flex-start"
//               gap="8"
//               mb="10"
//             >
//               <li class={listItemStyle}>
//                 <Box flexShrink="0">
//                   <CheckCheck aria-hidden="true" />
//                 </Box>
//                 <Typography tag="span" textStyle="small">
//                   {t("credits.24hSession")}
//                 </Typography>
//               </li>
//
//               <li class={listItemStyle}>
//                 <Box flexShrink="0">
//                   <CheckCheck aria-hidden="true" />
//                 </Box>
//                 <Typography tag="span" textStyle="small">
//                   {t("credits.markers")}
//                 </Typography>
//               </li>
//
//               <li class={listItemStyle}>
//                 <Box flexShrink="0">
//                   <CheckCheck aria-hidden="true" />
//                 </Box>
//                 <Typography tag="span" textStyle="small">
//                   {t("credits.overlay")}
//                 </Typography>
//               </li>
//             </styled.ul>
//
//             <Center w="full">
//               <Button
//                 tag="a"
//                 size="lg"
//                 ctx="dark"
//                 href={generateProductOverlayUrl({
//                   userId: user().id,
//                   email: user().email,
//                 })}
//                 class="lemonsqueezy-button"
//               >
//                 {t("credits.buyBtnLabel")}
//               </Button>
//             </Center>
//           </VStack>
//         </AppPageContainer>
//       )}
//     </Show>
//   );
// }
//
export default function CreditsPage() {
  return <Navigate href="/app/dashboard" />;
}
